import { QueryFunction, QueryKey, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

interface UsePrefetch {
  onMouseOver: VoidFunction
  onTouchStart: VoidFunction
}

/**
 * Hook to prefetch a react-query on hover or touch.
 */
function usePrefetch<TQueryFnData = unknown, TQueryKey extends QueryKey = QueryKey>(
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  queryKey: TQueryKey,
): UsePrefetch {
  const queryClient = useQueryClient()
  const prefetch = useCallback(
    () => queryClient.prefetchQuery(queryKey, queryFn, { retry: false }),
    [queryClient, queryFn, queryKey],
  )

  return {
    onMouseOver: prefetch,
    onTouchStart: prefetch,
  }
}

export default usePrefetch
