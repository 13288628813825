export class MyDate extends Date {
  subDays(days: number): MyDate {
    return new MyDate(this.setDate(this.getDate() - days))
  }
  startOfTheDay(): MyDate {
    return new MyDate(this.setHours(0, 0, 0, 0))
  }
  endOfTheDay(): MyDate {
    return new MyDate(this.setHours(23, 59, 59, 999))
  }
}

export const MIN_DATE = new MyDate().subDays(90).startOfTheDay() satisfies Date
export const MAX_DATE = new MyDate().endOfTheDay() satisfies Date

export const RANGE_OPTIONS = {
  today: {
    value: [new MyDate().startOfTheDay(), MAX_DATE],
  },
  yesterday: {
    value: [new MyDate().subDays(1).startOfTheDay(), new MyDate().subDays(1).endOfTheDay()],
  },
  last7Days: {
    value: [new MyDate().subDays(7).startOfTheDay(), MAX_DATE],
  },
  last30Days: {
    value: [new MyDate().subDays(30).startOfTheDay(), MAX_DATE],
  },
  thisMonth: {
    value: [new MyDate(new Date().setDate(1)).startOfTheDay(), MAX_DATE],
  },
  lastMonth: {
    value: [
      new MyDate(new Date(new MyDate().setMonth(new Date().getMonth() - 1)).setDate(1)).startOfTheDay(),
      new MyDate(new Date().setDate(0)).endOfTheDay(),
    ],
  },
  custom: {
    // Default value for custom range - last 30 days.
    value: [new MyDate().subDays(30).startOfTheDay(), MAX_DATE],
  },
  allTime: {
    value: [MIN_DATE, MAX_DATE],
  },
} as const satisfies Record<string, { value: readonly [Date, Date] }>

export type RangeOptionType = keyof typeof RANGE_OPTIONS

export const DEFAULT_RANGE = [...RANGE_OPTIONS.last30Days.value] satisfies readonly [Date, Date]
