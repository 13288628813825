import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  VisuallyHidden,
  FormLabel,
  InputRightElement,
  IconButton,
  FlexProps,
  InputProps,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import type { FunctionComponent } from 'react'

import CloseIcon from 'assets/close.svg?component'
import SearchIcon from 'assets/search.svg?component'
import type { UseTable } from 'hooks/useTable'

interface TableSearchProps extends Pick<FlexProps, 'width' | 'height'>, InputProps {
  onChange: UseTable['setSearch']
  search: UseTable['search']
}

const TAG = 'TableSearch'

const TableSearch: FunctionComponent<TableSearchProps> = ({ onChange, search, width, height, ...props }) => {
  const { t } = useTranslation()
  const resetSearch = () => onChange('')

  /**
   * As the Input element is placed inside the Form element,
   * some browsers will submit the form when the user presses Enter.
   * This function prevents the form from being submitted when the user presses Enter.
   * */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  // TODO: Use FormInput instead to be consistent with the rest of the app
  // @see src\components\modules\Equipment\Details\TabDataExplore\SelectSignals\SignalSearch\index.tsx
  return (
    <Flex as="form" p={6} width={width}>
      <InputGroup>
        <VisuallyHidden as={FormLabel}>{t('components.table.search')}</VisuallyHidden>

        <InputLeftElement height={height}>
          <Icon as={SearchIcon} aria-hidden="true" color="gray.600" />
        </InputLeftElement>
        <Input
          onChange={onChange}
          value={search}
          placeholder={props.placeholder || t('components.table.search')}
          border="none"
          borderRadius="0"
          backgroundColor="gray.100"
          _focusVisible={{ border: 'none' }}
          height={height}
          onKeyDown={handleKeyDown}
          {...props}
        />
        {!!search && (
          <InputRightElement height={height}>
            <IconButton
              data-clear-field
              size="xs"
              variant="ghost"
              color="primary.red"
              icon={<Icon as={CloseIcon} boxSize={3} />}
              aria-label={t('components.table.clear')}
              onClick={resetSearch}
              minH={6}
              _hover={{ bg: 'gray.300' }}
              _focusVisible={{ bg: 'gray.300' }}
              isRound
            />
          </InputRightElement>
        )}
      </InputGroup>
    </Flex>
  )
}

TableSearch.displayName = TAG

export default TableSearch
