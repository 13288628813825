import type { TranslateFun } from 'next-translate/useTranslation'

import type { TableFilterKey, FilterOption, TableFilter } from 'components/modules/Equipment/Filters/types'
import { Categories, isCategoryName } from 'config/categories'
import compare from 'utils/compare'

const getFilter = (
  key: TableFilterKey,
  options: string[] | FilterOption[],
  tableFilters: TableFilter[],
): TableFilter => {
  return { key, options, selectedOptions: tableFilters.find((_) => _.key === key)?.selectedOptions || [] }
}

const getBooleanFilter = (key: TableFilterKey, t: TranslateFun<'common'>, tableFilters: TableFilter[]): TableFilter => {
  return getFilter(
    key,
    [
      { label: t('components.filters.options.yes'), value: 'true' },
      { label: t('components.filters.options.no'), value: 'false' },
    ],
    tableFilters,
  )
}

export function initializeEquipmentFilters(tableFilters: TableFilter[], t: TranslateFun<'common'>): TableFilter[] {
  const filters: TableFilter[] = [
    getBooleanFilter('isSmartDrive', t, tableFilters),
    getBooleanFilter('isConnected', t, tableFilters),
  ]

  const processFilterOptions = Object.keys(Categories)
    .filter(isCategoryName)
    .map((key) => ({ label: t(`components.categories.${key}.title`), value: `${Categories[key]}` }))
    .sort((a, b) => compare(a.label, b.label))
  filters.push(getFilter('process', processFilterOptions, tableFilters))

  return filters
}

export function initializeConnectedEquipmentFilters(
  equipmentList: Database.EquipmentListItemConnected[] | undefined,
  tableFilters: TableFilter[],
  t: TranslateFun<'common'>,
): TableFilter[] {
  const filters: TableFilter[] = []
  filters.push(getBooleanFilter('isSmartDrive', t, tableFilters))

  if (!equipmentList) {
    return filters
  }

  const siteFilterOptions = new Set<string>()
  const makeYearFilterOptions = new Set<string>()
  equipmentList.map((equipment) => {
    if (equipment?.site) {
      siteFilterOptions.add(equipment.site)
    }
    if (equipment?.makeYear) {
      makeYearFilterOptions.add(equipment.makeYear)
    }
  })

  if (siteFilterOptions.size > 0) {
    filters.push(getFilter('site', Array.from(siteFilterOptions), tableFilters))
  }
  if (makeYearFilterOptions.size > 0) {
    filters.push(getFilter('makeYear', Array.from(makeYearFilterOptions), tableFilters))
  }

  return filters
}

export function initializeSparePartsFilter(
  sparePartsList: SparePart[] | undefined,
  tableFilters: TableFilter[],
  t: TranslateFun<'equipment'>,
): TableFilter {
  let filter: TableFilter = {
    key: 'serviceInterval',
    options: [],
    selectedOptions: [],
  }

  if (!sparePartsList) {
    return filter
  }

  const serviceIntervalFilterOptions: Array<FilterOption> = []
  sparePartsList.map(({ serviceInterval, displayServiceInterval }) => {
    const isAlreadyAdded = serviceIntervalFilterOptions.find((option) => option.value === serviceInterval)

    if (!isAlreadyAdded) {
      serviceIntervalFilterOptions.push({
        label: displayServiceInterval || t('pages.details.spareParts.table.additionalRecommendation'),
        value: serviceInterval,
      })
    }
  })

  if (serviceIntervalFilterOptions.length > 0) {
    filter = getFilter(
      'serviceInterval',
      serviceIntervalFilterOptions.sort((a, b) => compare(a.value, b.value)),
      tableFilters,
    )
  }

  return filter
}
