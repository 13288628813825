import { Box, BoxProps, Container, Heading, SystemStyleInterpolation } from '@chakra-ui/react'
import type { FunctionComponent, ReactNode } from 'react'

import Image from 'components/elements/Image'

interface HeroImageProps extends Omit<BoxProps, 'title'> {
  src: string
  title: ReactNode
  subtitle?: ReactNode
}

const TAG = 'HeroImage'

const IMAGE_WIDTH = { base: 480, sm: 720, md: 960, lg: 1360 }
const IMAGE_HEIGHT = 300

const styles = {
  header: {
    position: 'relative',
    display: 'grid',
    maxW: 'full',
    maxH: IMAGE_HEIGHT,
  },
  wrapper: {
    width: 'full',
    minH: IMAGE_HEIGHT,
    height: IMAGE_HEIGHT,
    gridArea: '1 / -1',
    marginInline: 'auto',
    minWidth: 0,
    sx: {
      '[data-shadow="true"] > &': {
        _after: {
          content: '""',
          position: 'absolute',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.2)',
        },
      },
    },
  },
  image: {
    maxW: '100%',
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    minH: '100%',
    minWidth: '100%',
    // minWidth has higher priority than maxWidth,
    // but that will fix overflow when image couldn't be loaded
    maxWidth: '0',
    userSelect: 'none',
  },
  container: {
    zIndex: 1,
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'end',
    flexDirection: 'column',
    maxW: 'desktop-content',
    gridArea: '1 / -1',
    p: { base: 4, md: 6, xl: 10 },
  },
  title: {
    display: 'block',
    size: { base: 'lg', md: 'xl' },
  },
  subtitle: {
    display: 'block',
    size: { base: 'xs', md: 'sm' },
    fontWeight: 'normal',
  },
} as const satisfies SystemStyleInterpolation

const HeroImage: FunctionComponent<HeroImageProps> = ({ title, src, subtitle, ...props }) => (
  <Box data-testid={TAG} as="header" {...styles.header} data-shadow={true} {...props}>
    <Box {...styles.wrapper}>
      <Image
        src={src || '/images/hero-fallback-image.jpg'}
        alt=""
        role="presentation"
        priority={true}
        loading="eager"
        backgroundColor={props.bgColor || props.backgroundColor}
        {...styles.image}
      />
    </Box>
    <Container {...styles.container}>
      {subtitle && (
        <Heading as="span" {...styles.subtitle}>
          {subtitle}
        </Heading>
      )}
      {title && (
        <Heading as="h1" {...styles.title}>
          {title}
        </Heading>
      )}
    </Container>
  </Box>
)

export default HeroImage
