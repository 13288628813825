function filterBySearch<VALUE extends object>(search: string) {
  const filter = (prop: string | null): boolean =>
    prop ? `${prop}`.toLowerCase().includes(search.toLowerCase()) : false

  return function filterInternal(value: VALUE): boolean {
    if (!search) {
      return true
    }

    return Object.values(value).some((value) => filter(value))
  }
}

export default filterBySearch
