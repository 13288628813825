import { Flex, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import type { FunctionComponent } from 'react'

interface TableTotalCountProps {
  label: TranslationKeys['common']
  totalCount: number
  /**
   * Pass `null` to hide the filtered count
   */
  filteredCount: number | null
}

const TAG = 'TableTotalCount'

const TableTotalCount: FunctionComponent<TableTotalCountProps> = ({ totalCount, filteredCount, label }) => {
  const { t } = useTranslation()

  return (
    <Flex minH="65px" align="center" px={6}>
      <Text as="span">
        {t(label)}
        {filteredCount === null ? (
          <b> {totalCount}</b>
        ) : (
          <>
            <b> {filteredCount} </b>
            {t('components.table.of')}&nbsp;
            {totalCount}
          </>
        )}
      </Text>
    </Flex>
  )
}

TableTotalCount.displayName = TAG

export default TableTotalCount
