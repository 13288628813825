import { RANGE_OPTIONS, RangeOptionType } from 'components/elements/RangePicker/config'

/**
 * @returns {RangeOptionType} based on the value
 */
function getTypeFromDates(value: readonly [Date, Date | null]): RangeOptionType {
  const [start, end] = value
  if (!end) {
    return 'custom'
  }

  for (const [key, { value }] of Object.entries(RANGE_OPTIONS)) {
    const [rangeStart, rangeEnd] = value
    if (start.getTime() === rangeStart.getTime() && end.getTime() === rangeEnd.getTime()) {
      return key as RangeOptionType
    }
  }

  return 'custom'
}

export default getTypeFromDates
