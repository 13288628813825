import {
  Button,
  Checkbox,
  CheckboxGroup,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  SystemStyleInterpolation,
  Text,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useCallback } from 'react'

import ChevronIcon from 'assets/chevron.svg?component'
import type { EquipmentFilterKey, TableFilter } from 'components/modules/Equipment/Filters/types'

interface EquipmentFilterMultiselectProps {
  filter: TableFilter
  setFilter: (selectedOption: TableFilter) => void
}

const styles = {
  menuButton: {
    bg: 'white',
    color: 'black',
    borderWidth: '1px',
    borderColor: 'grayCCC',
    fontWeight: 'light',
    textAlign: 'left',
    w: { base: 'full', md: '240px' },
    h: '56px',
    _expanded: { bg: 'gray.50' },
    _hover: { bg: 'white' },
    _focusVisible: { bg: 'gray.50' },
  },
  menuIcon: {
    boxSize: 3,
    ml: 3,
    transform: 'rotate(90deg)',
  },
  menuText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxW: 'calc(100% - 25px)',
    display: 'inherit',
  },
  menuList: {
    w: 'full',
    p: 0,
    borderRadius: 0,
  },
  wrapper: {
    w: 'full',
    direction: 'column',
  },
  checkbox: {
    w: 'full',
    size: 'md',
    p: 2,
    borderBottom: '1px',
    borderColor: 'grayCCC',
  },
} as const satisfies SystemStyleInterpolation

const EquipmentFilterMultiselect: FunctionComponent<EquipmentFilterMultiselectProps> = ({ filter, setFilter }) => {
  const { t } = useTranslation()

  const onChangeHandler = useCallback(
    (selected: string[]) =>
      setFilter({
        key: filter.key,
        selectedOptions: selected,
      }),
    [filter.key, setFilter],
  )

  return (
    <Menu>
      <MenuButton as={Button} {...styles.menuButton} rightIcon={<Icon as={ChevronIcon} {...styles.menuIcon} />}>
        <Text {...styles.menuText}>
          {t(`pages.equipment.${filter.key as EquipmentFilterKey}`)}
          {filter.selectedOptions.length > 0 ? ` (${filter.selectedOptions.length})` : ''}
        </Text>
      </MenuButton>
      <MenuList {...styles.menuList}>
        <CheckboxGroup value={filter.selectedOptions} onChange={onChangeHandler}>
          <Stack {...styles.wrapper}>
            {filter.options?.map((option, index) => (
              <Checkbox key={index} value={typeof option === 'string' ? option : option.value} {...styles.checkbox}>
                {typeof option === 'string' ? option : option.label}
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      </MenuList>
    </Menu>
  )
}

export default EquipmentFilterMultiselect
