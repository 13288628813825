import { Button, Flex, SystemStyleInterpolation } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import RangePicker from 'components/elements/RangePicker'
import EquipmentFilterMultiselect from 'components/modules/Equipment/Filters/EquipmentFilterMultiselect'
import type { TableFilter } from 'components/modules/Equipment/Filters/types'

interface EquipmentFiltersProps {
  filters: TableFilter[]
  setFilter: (selectedOption: TableFilter) => void
  resetFilters: () => void
  dateRange?: [Date, Date]
  setDateRange?: (value: [Date, Date]) => void
}

const TAG = 'EquipmentFilters'

const styles = {
  container: {
    marginBlockStart: '10',
    marginBlockEnd: '14',
    alignItems: { base: 'stretch', md: 'center' },
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: 6,
    direction: { base: 'column-reverse', md: 'row' },
  },
  wrapper: {
    gap: 6,
    flexWrap: 'wrap',
    minH: 14,
  },
  button: {
    h: '54px',
    w: { base: 'full', md: 'min-content' },
    color: 'primary.red',
  },
} as const satisfies SystemStyleInterpolation

const EquipmentFilters: FunctionComponent<EquipmentFiltersProps> = ({
  filters,
  setFilter,
  resetFilters,
  dateRange,
  setDateRange,
}) => {
  const { t } = useTranslation()
  const isFilterApplied = filters?.length > 0 && filters.filter((_) => _.selectedOptions?.length > 0)?.length > 0

  return (
    <Flex data-testid={TAG} {...styles.container}>
      <Flex {...styles.wrapper}>
        {filters?.map((filter) => (
          <EquipmentFilterMultiselect key={filter.key} filter={filter} setFilter={setFilter} />
        ))}
        {isFilterApplied && (
          <Button variant="ghost" onClick={resetFilters} {...styles.button}>
            {t('components.filters.clear-filters')}
          </Button>
        )}
      </Flex>
      {dateRange && setDateRange && <RangePicker onChange={setDateRange} value={dateRange} />}
    </Flex>
  )
}

EquipmentFilters.displayName = TAG

export default EquipmentFilters
