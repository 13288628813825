import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import Router, { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'

import useMemoCompare from 'hooks/useMemoCompare'

const DEBOUNCE_TIME = 300

/**
 * Hook to update query params.
 * Hook uses JSON.stringify to convert values to string.
 *
 * @example
 * ```tsx
 * const [search, setSearch] = useState('')
 * useUpdateQueryParams({ search });
 * ```
 */
function useUpdateQueryParams<VALUE extends object>(value: VALUE): void {
  const { query, isReady } = useRouter()

  const updateQueryParamDebounced = useMemo(
    () => debounce((query) => Router.replace({ query }, void 0, { shallow: true, scroll: false }), DEBOUNCE_TIME),
    [],
  )

  const newQuery = useMemoCompare(() => {
    const newQuery = { ...query }
    Object.keys(value).forEach((key) => {
      const val = value[key as keyof VALUE]
      if (val !== undefined && val !== null) {
        newQuery[key] = JSON.stringify(val)
      } else {
        delete newQuery[key]
      }
    })
    return newQuery
  }, isEqual)

  useEffect(() => {
    if (isReady) {
      updateQueryParamDebounced(newQuery)

      return () => {
        updateQueryParamDebounced.cancel()
      }
    }
  }, [isReady, newQuery, updateQueryParamDebounced])
}

export default useUpdateQueryParams
