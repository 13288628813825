import type { QueryFunctionContext } from '@tanstack/react-query'

import { RoutePaths } from 'config/routes'
import apiFetch from 'utils/apiFetch'

type Arguments = QueryFunctionContext<[queryFnName: 'getEquipmentDetails', erpId: Database.ERPId, serialNumber: string]>

function getEquipmentDetails({
  signal,
  queryKey: [, erpId, serialNumber],
}: Arguments): Promise<Database.EquipmentDetails> {
  if (!erpId || !serialNumber) {
    return Promise.reject({ message: 'Missing erpId and serialNumber.' })
  }

  const params = new URLSearchParams({ erpId, serialNumber }).toString()
  return apiFetch<Database.EquipmentDetails>(`${RoutePaths.apiEquipmentDetails}?${params}`, { signal })
}

export default getEquipmentDetails
