import { Box, Popover, ThemeComponents } from '@chakra-ui/react'
import isEqual from 'lodash/isEqual'
import dynamic from 'next/dynamic'
import React, { FunctionComponent, memo, useEffect, useState } from 'react'

import type { RangeOptionType } from 'components/elements/RangePicker/config'
import RangePickerTrigger from 'components/elements/RangePicker/Trigger'
import getTypeFromDates from 'components/elements/RangePicker/utils/getTypeFromDates'
import { mediaScreenMax } from 'theme/foundations/breakpoints'

const RangePickerContent = dynamic(
  () => import(/* webpackChunkName: "RangePickerContent" */ 'components/elements/RangePicker/Content'),
)

interface RangePickerProps {
  /**
   * @default 'last 7 days'
   * @see {@link DEFAULT_RANGE}
   */
  value: Readonly<[Date, Date]>
  /**
   * Callback that will be fired when an user clicks the apply button.
   */
  onChange: (value: [Date, Date]) => void
}

const TAG = 'RangePicker'

const styles = {
  baseStyle: {
    popper: {
      zIndex: 1000,
      minHeight: 'auto',

      [mediaScreenMax('lg')]: {
        position: 'fixed !important',
        inset: '0 0 0 50% !important',
        transform: 'none !important',
        maxHeight: '100vh',
        minHeight: '100%',
        overflow: 'auto',
        overscrollBehavior: 'contain',
        '@supports (max-height: 100dvh)': {
          maxHeight: '100dvh',
        },
      },
      [mediaScreenMax('md')]: {
        inset: '0 !important',
      },
    },
  },
} as const satisfies ThemeComponents['Popover']

const RangePicker: FunctionComponent<RangePickerProps> = ({ value: savedValue, onChange }) => {
  const [type, setType] = useState<RangeOptionType>(getTypeFromDates(savedValue))

  useEffect(() => {
    setType(getTypeFromDates(savedValue))
  }, [savedValue])

  return (
    <Box data-testid={TAG}>
      <Popover
        data-testid={`${TAG}-popover`}
        placement="bottom-end"
        flip={false}
        isLazy
        lazyBehavior="unmount"
        styleConfig={styles}
      >
        {({ onClose }) => (
          <>
            <RangePickerTrigger value={savedValue} type={type} />
            <RangePickerContent savedValue={savedValue} onChange={onChange} onClose={onClose} setType={setType} />
          </>
        )}
      </Popover>
    </Box>
  )
}

RangePicker.displayName = TAG

export default memo(RangePicker, isEqual)
