import { Button, Icon, PopoverTrigger, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import CalendarIcon from 'assets/calendar.svg?component'
import ChevronSolidIcon from 'assets/chevronSolid.svg?component'
import type { RangeOptionType } from 'components/elements/RangePicker/config'
import { mediaReducedMotion } from 'theme/foundations/breakpoints'
import formatDate from 'utils/formatDate'

interface RangePickerTriggerProps {
  value: readonly [Date, Date]
  type: RangeOptionType
}

const TAG = 'RangePickerTrigger'

const styles = {
  button: {
    minH: '14',
    minW: { base: 240, lg: 270 },
    padding: '4',
    border: '.125rem solid',
    borderColor: 'gray.800',
    fontWeight: 'bold',
    size: 'md',
    alignItems: 'center',
    justifyContent: 'flex-start',
    display: 'flex',
    flexShrink: 0,
    w: { base: 'full', md: 'min-content' },
    maxW: '100%',
    _hover: {
      backgroundColor: 'gray.200',
    },
    _focusVisible: {
      backgroundColor: 'gray.200',
    },
  },
  calendar: {
    color: 'primary.red',
    boxSize: '5',
    marginInlineEnd: { base: '0', lg: '3' },
  },
  chevron: {
    color: 'primary.red',
    boxSize: '6',
    marginInlineStart: { base: '0', lg: '5' },

    sx: {
      [mediaReducedMotion('no-preference')]: {
        transition: 'transform .2s',
      },

      '[aria-expanded="true"] > span > &': {
        transform: 'rotate(-180deg)',
      },
    },
  },
  dates: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 'sm',
    marginBlockStart: '.625rem',
    marginInlineEnd: 'auto',
  },
  type: {
    position: 'absolute',
    insetBlockStart: 2,
    insetInlineStart: { base: '2.75rem', lg: 14 },
    fontWeight: 400,
    fontSize: 'xs',
  },
} as const satisfies SystemStyleInterpolation

const START_DATE_OPTIONS = { month: 'short', day: '2-digit' } as const satisfies Intl.DateTimeFormatOptions

function formatRange(value: readonly [Date, Date], lang: string, type: RangeOptionType) {
  switch (type) {
    case 'today':
    case 'yesterday':
      return formatDate(lang, value[0])

    default:
      return `${formatDate(lang, value[0], START_DATE_OPTIONS)} - ${formatDate(lang, value[1])}`
  }
}

const RangePickerTrigger: FunctionComponent<RangePickerTriggerProps> = ({ value, type }) => {
  const { t, lang } = useTranslation()

  return (
    <PopoverTrigger>
      <Button
        {...styles.button}
        data-testid={TAG}
        variant="unstyled"
        leftIcon={<Icon aria-hidden as={CalendarIcon} {...styles.calendar} />}
        rightIcon={<Icon aria-hidden as={ChevronSolidIcon} {...styles.chevron} />}
      >
        <Text as="span" {...styles.type}>
          {t(`components.rangePicker.type.${type}`)}
        </Text>
        <Text as="span" {...styles.dates}>
          {formatRange(value, lang, type)}
        </Text>
      </Button>
    </PopoverTrigger>
  )
}

RangePickerTrigger.displayName = TAG

export default RangePickerTrigger
